.skills-main-div {
  display: block;
  overflow: hidden;
  border-style: solid;
  border-radius: 23px;
  box-shadow: 5px 5px 12px gray;
}

.card:hover {
  background-color: rgb(255, 255, 255);
  z-index: 1;
  border-radius: 23px;
  border-color: rgb(255, 255, 255);
  opacity: 1;
}

.card {
  opacity: 0.8;
  border-color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.5);
}

#skills {
  max-width: 25%;
  height: fit-content;
}

#skills a {
  color: white;
}

.skills-outer {
  display: flex;
  flex-wrap: wrap;
  max-width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}

.skills-header {
  font-size: 60px;
  line-height: normal;
  font-family: "Montserrat";
  text-align: center;
}

.skills-text {
  font-size: 20px;
  color: #02150e;
}

.skills-text-div {
  font-family: "Montserrat";
}

.skills-main-div > * {
  flex: 1;
}

.skills-image-div > * {
  height: 300px;
  width: 300px;
}

.skills-heading {
  font-size: 20px;
  font-weight: bold;
  font-family: "Montserrat";
  line-height: normal;
  color: #02150e;
  text-align: center;
}

/* Media Query */
@media (max-width: 1380px) {
  .skills-heading {
    font-size: 30px;
  }
  .skills-header {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .skills-heading {
    font-size: 20px;
    text-align: center;
  }
  .skills-header {
    font-size: 20px;
  }
  .skills-main-div {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items in column layout */
  }
  .skills-text-div {
    margin: 20px;
    text-align: center; /* Center text for better readability */
  }
  .skills-text {
    font-size: 16px;
  }
  .skills-text-subtitle {
    font-size: 16px;
    text-align: center;
  }
  .skills-image-div {
    order: 2;
    width: 100%; /* Allow image to take full width */
    display: flex;
    justify-content: center; /* Center image */
  }
  .skills-image-div > * {
    margin-top: 0px;
    height: 200px; /* Adjust image size for smaller screens */
    width: 200px;
  }
}

@media (max-width: 480px) {
  .skills-header {
    font-size: 18px;
  }
  .skills-text {
    font-size: 14px;
  }
  .skills-image-div > * {
    height: 150px;
    width: 150px;
  }
}
