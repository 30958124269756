/* header */

.header {
  display: block;
  max-width: 100%;
  padding: 20px 10px;
  margin: 0 auto;
  background: #02150e;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}

.grey-color {
  color: #868e96;
}

.header li {
  display: inline;
}

.header li a {
  display: inline-block;
  padding: 20px;
  color: #fff; /* Set default color for links */
  text-decoration: none;
  transition: color 0.3s ease, background-color 0.3s ease; /* Smooth transition */
}

.header li a:hover,
.header .menu-btn:hover {
  background-color: #02150e;
  color: #ffce1b;
}

.header .logo {
  display: inline;
  font-size: 1.5em;
  text-decoration: none;
  margin-top: 10px;
  line-height: normal;
}

.header .logo-name {
  font-family: "Agustina Regular";
  font-weight: bold;
  padding: 0 25%;
  color: #55198b; /* Uncommented to apply color */
}

/* menu */

.menu {
  font-family: "Montserrat";
  background: #02150e;
  display: flex;
  flex-direction: column; /* Stack items vertically by default */
}

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
  padding-right: 5%;
  overflow: hidden; /* Ensure menu is hidden when collapsed */
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #fff; /* Ensure visibility of the icon */
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #fff;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 370px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 48em) {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header .menu {
    max-height: none;
    flex-direction: row; /* Align items horizontally on larger screens */
  }
  .header .menu-icon {
    display: none;
  }
}

@media (max-width: 960px) and (min-width: 768px) {
  .header {
    font-size: 12px;
  }
}
